import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import '../style/rfp.css'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import logo from '../images/srdsLogo.png';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


export default class StepperRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.onClickSupp = this.onClickSupp.bind(this)
        this.onClickDate = this.onClickDate.bind(this)
        this.onClickBudget = this.onClickBudget.bind(this)
        this.onClickRates = this.onClickRates.bind(this)
        this.onClickSummery = this.onClickSummery.bind(this)

        this.state = {
            titles: localStorage.getItem('title'),
            treatedTitles:[],
            currentStep: this.props.currentStep,
        };

    }

    onClickSupp(title: any) {
        const newTitle: any = []
        let i = 0
        let j = 0
        this.props.titles.forEach((tit: any, index: any) => {
            if(tit.id !== title){
                newTitle[i] = tit
                i++
            } else {
                j = i
            }
        });
        if(newTitle.length > 0 ){
            if(j === 0 || newTitle.length !== j){
                this.props.handleToUpdate(newTitle[j].id)
                localStorage.setItem('selectedIndex', JSON.stringify(j))
            }else{
                this.props.handleToUpdate(newTitle[j-1].id)
                localStorage.setItem('selectedIndex', JSON.stringify(j-1))
            }
        }else{
            localStorage.removeItem('selectedIndex')
            localStorage.removeItem('rates')
        }
        // updated localStorage titles
        this.setState({titles: newTitle})
        this.props.handleChange('titles', newTitle)
        localStorage.setItem('title', JSON.stringify(newTitle))
        // updated localStorage rates
        const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]');
        const newRates = selectedRates.filter((rate:any)=>{
            return newTitle.some((title:any)=>{
                return rate.srds_mid == title.id
            });
        });
        const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
        const newTitleInfo = titleInfo.filter((info:any)=>{
            return newTitle.some((title:any)=>{
                return info.srds_mid == title.id
            });
        });
        localStorage.setItem('titleInfo', JSON.stringify(newTitleInfo))
        if(selectedRates.length > 0){
            localStorage.setItem('selectedRates', JSON.stringify(newRates))
        }
    }

    submit = (id: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='divAlert_react'>
                        <div className="divAlert_fond p-4">
                            <div>
                                <span className="divIconAlert">
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                </span>
                                <h5>Do you want to continue?</h5> 
                            </div>
                            <div className="divAlert_texte">                        <p>This title will be deleted from your campaign request.</p>    
                            </div>
                            <div className="divBtnOk">
                                <button className="btn btnOk" onClick={onClose}>Cancel</button>
                                <button className="btn btnOk btn_Ok"
                                onClick={() => {
                                    this.onClickSupp(id);
                                    onClose();
                                }}
                            >
                                Continue
                            </button>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    onClickDate(){
        this.setState({
            currentStep: 1
        })
        this.props.onCurrentStepChange(1)
    }

    onClickBudget(){
        var startDate = localStorage.getItem('dateStart') ? localStorage.getItem('dateStart') :'';
        startDate = startDate !== null ? startDate.replace(/"/g, '') : '';
        if(startDate){
            this.setState({
                currentStep: 2
            })
            this.props.onCurrentStepChange(2)
        }
    }
    onClickRates(){
        var startDate = localStorage.getItem('dateStart') ? localStorage.getItem('dateStart') :'';
        startDate = startDate !== null ? startDate.replace(/"/g, '') : '';
        if(startDate){
            this.setState({
                currentStep: 3
            })
            this.props.onCurrentStepChange(3)
        }
    }
    onClickSummery(){
        var startDate = localStorage.getItem('dateStart') ? localStorage.getItem('dateStart') :'';
        startDate = startDate !== null ? startDate.replace(/"/g, '') : '';
        if(startDate && this.props.titles.length == this.getTitleCount().length){
            this.setState({
                currentStep: 4
            })
            this.props.onCurrentStepChange(4)
        }
    }
    getTitleCount(){
        const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]');
        const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
    
        const titleCount:any = [] 
        selectedRates.map((e:any)=>{
          if (titleCount.indexOf(e.srds_mid) === -1) {
              titleCount.push(e.srds_mid)
          }
          })
          titleInfo.map((e:any)=>{
              if (titleCount.indexOf(e.srds_mid) === -1 && (e.titleComment && e.titleComment !== "" || e.titleFile && e.titleFile !== "") ) {
                  titleCount.push(e.srds_mid)
              }
              })
          return titleCount;
    }
    render() {
        const titles = this.props.titles;
        const selectedTitle = localStorage.getItem('selectedTitle')
        var handleToUpdate  =   this.props.handleToUpdate;
        const startDate = this.props.dateStart ? this.props.dateStart : '';
        const endDate = this.props.dateEnd ? this.props.dateEnd : '';
        const budget = this.props.budget ? this.props.budget.replace(/"/g, '') : '';
        const style = {
            marginTop: "30%",
          }
          const treatedTitles = this.getTitleCount();
          localStorage.setItem('treatedTitles',treatedTitles.length)
        return (
            <div className="divL">
                <div className="divTitleSom disFlex">
                    <div className="width50">
                        <h3 className="titleColor">
                            <img src={logo} alt="" style={{maxWidth:"170px"}} />
                        </h3>
                    </div>
                    <div className="width50 colorGrey">
                        Streamline the ad buying process by negotiating rates and sending RFIs via SRDS.&nbsp; 
                        <a href="https://next.srds.com/resources/programmatic-resources/buying" target="_blank">  Learn more</a>
                    </div>
                </div>
                {this.props.currentStep !== 5 ?
                <div className="list">
                    <h3 id="titleStep">Campaign Request</h3>
                    <ol className="olList">
                        <li onClick={this.onClickDate} className={this.props.currentStep > 1 ? "liList disable" : this.props.currentStep === 1 ? this.props.dateBool ? "liList activeStep" : "liList activeList" : "liList"}>
                            <div className="pDateStepper">
                                <p className="pTitleStepper">Campaign Dates</p>
                                    {
                                        startDate ?
                                        <small className="text-dark styleAnno">{startDate.toString()} - {endDate.toString()}</small>
                                        : ''
                                    }
                            </div>
                        </li>
                        <li onClick={this.onClickBudget} className={this.props.currentStep > 2 ? "liList disable" : this.props.currentStep === 2 ? this.props.budgetBool ? "liList activeStep" : "liList activeList" : this.props.budgetBool ? "liList greenStep" :"liList"}>
                            {
                                budget ?
                                    <div className="pDateStepper">
                                        <p className="pTitleStepper">Budget (Optional)</p>
                                        <small className="text-dark styleAnno">
                                            <NumberFormat value={budget} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </small>
                                    </div>
                                :
                                    <div className="pStepper">
                                        <p className="pTitleStepper">Budget (Optional)</p>
                                    </div>
                            }
                        </li>
                        <li onClick={this.onClickRates} className={this.props.currentStep > 3 ? "liList disable" : this.props.currentStep === 3 ? this.props.rateBool ? "liList activeStep" : "liList activeList" : this.props.rateBool ? "liList greenStep" :"liList"}>
                            <p className="pStepper">Ad Units</p>
                        </li>
                        <li onClick={this.onClickSummery} className={this.props.currentStep > 4 ? "liList disable" : this.props.currentStep === 4 ? "liList activeList" : "liList"}>
                            <p className="pStepper">Summary</p>
                        </li>
                    </ol>
                    <div className="row divTitlesSelected">
                        <p className="col-md-6">Selected Titles </p>
                        <p className="col-md-6 titleCount">{treatedTitles.length}/{titles.length}</p>
                    </div>
                    <div className="titles">
                        { titles && titles.length > 0 ?
                            titles
                                .map((title : any, index: any) => (
                                <div className={`card px-3 mb-2 ${title.id === selectedTitle && this.props.currentStep > 2 ? "selectedTitleColor" : "" } ${this.props.currentStep > 2 ? "cardHover" : ""} `  } key={index}>
                                    <div className="disFlex divTitleCard" key={index}>
                                        {
                                            this.props.currentStep > 1 ?
                                                <div className="titleCards" onClick={() => handleToUpdate(title.id, -1)} >
                                                    <p className="pCards"> {title.name}</p>
                                                    <small className="desCard">{title.id.replace(/(?=.{3}$)/,'-') }  {title.media_characteristic}</small>
                                                </div>
                                            :
                                                <div className="titleCards">
                                                    <p className="pCards"> {title.name}</p>
                                                    <small className="desCard">{title.id.replace(/(?=.{3}$)/,' ')}  {title.media_characteristic}</small>
                                                </div>
                                        }
                                        <div>
                                            <Button className={`btnSuppCarts margBtnSup ${title.id === selectedTitle ? "colorSelected" : "" }`} onClick = {() => this.submit(title.id)}><FontAwesomeIcon className="fontTimes" icon={faTimes} /></Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            ""
                        }
                    </div>
                    <div className="version"><span>v1.4</span></div>
                </div>
                :
                <div className="thanksPage h-50" style={style}>
                    <p className="font-weight-bold tyStyle">Thank you</p>
                </div>
                }
            </div>
        )
    }
}
