import React, { Component} from 'react';
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";



export default class CalendarRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.resetCalendar = this.resetCalendar.bind(this)
        this.state = {
            startDate: this.props.dateStart?this.props.dateStart:null,
            endDate: this.props.dateEnd?this.props.dateEnd:null,
            startOpen: false
        }
    }

    resetCalendar() : void{
        localStorage.setItem("dateStart", '');
        localStorage.setItem("dateEnd", '');
        this.setState  ({
            startDate: null,
            endDate: null, 
        });
        this.props.onDateComplete();
    }

    render(){
        // console.log(this.state.startOpen)
        return (
            
            <div>
                <div className="divTitle">
                    <h3 className="styleTitle">Enter Campaign Dates</h3>
                </div>
                <div className="react_calendar_input">
                    <label className="label_calendar">Start of campaign</label>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.startDate?new Date(this.state.startDate):undefined}
                        onChange={date => this.setStartDate(date)}
                        locale={enGB}
                        minDate={new Date()}
                    />
                </div>
                
                    
                <div className="react_calendar_input">
                    <label className="label_calendar">End of campaign</label>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        selected={this.state.endDate?new Date(this.state.endDate):undefined}
                        onInputClick = {()=> this.setState({startOpen: true})}
                        onChange={date => this.setEndDate(date)}
                        open={this.state.startOpen}
                        shouldCloseOnSelect = {true}
                        locale={enGB}
                        onBlur={()=> this.setState({startOpen: false})}
                        minDate={this.state.startDate ? new Date(this.state.startDate):new Date()}
                    />
                </div>
                
                <div className="calendarReset">
                    <a className="clearButton" href="#" onClick={this.resetCalendar}>Clear Calendar</a>
                </div>
            </div>
        )
    }

    private setEndDate(date: Date | [Date, Date] | null) {
        console.log(date)
        if (date && date as Date) {
            this.setState({
                endDate: date,
                startOpen: false
            },()=>{
                this.props.onDateChange("dateEnd", moment(date.toString()).format("L") , true);
                this.props.onDateComplete(this.state.startDate && this.state.endDate);
            })
        }else{
            this.setState({
                endDate: null
            })
            this.props.onDateChange("dateEnd", null , false);
            localStorage.removeItem("dateEnd");
        }
    }

    private setStartDate(date: Date | [Date, Date] | null) {
        if (date && date as Date) {
            if(this.state.endDate ? date > new Date(this.state.endDate) : false){
                this.setState({
                    endDate: null
                })
                this.props.onDateChange("dateEnd", null , false);
                localStorage.removeItem("dateEnd");
                this.setState({
                    startDate: date,
                    startOpen: true
                }, () => {
                    this.props.onDateChange("dateStart", moment(date.toString()).format("L") , true);
                    this.props.onDateComplete(this.state.startDate && this.state.endDate);
                }) 
            }
            else if(this.state.endDate === null){
                this.setState({
                    startDate: date,
                    startOpen: true
                }, () => {
                    this.props.onDateChange("dateStart", moment(date.toString()).format("L") , true);
                    this.props.onDateComplete(this.state.startDate && this.state.endDate);
                })               
            }
            else{
                this.setState({
                    startDate: date,
                    startOpen: false
                })
            }
        }else{
            this.setState({
                startDate: null
            })
            this.props.onDateChange("dateStart", null , false);
            localStorage.removeItem("dateStart");
        }
    }
}
