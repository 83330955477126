import * as React from 'react'
import BudgetRfp from './BudgetRfp'
import RatesRfp from './RatesRfp'
import SummaryRfp from './SummaryRfp'
import SuccessRfp from './SuccessRfp'
import CalendarRfp from './CalendarRfp'

class TProps {
    step: number = 1;
    dateStart: any;
    dateEnd: any;
    comment: any;
    titleComment: any;
    rates: any;
    titles: any; 
    budget: any;
    phone: any;
    email: any;
    updateTitleInfo:any;
    titleInfo:any;
    selectedIndex:any;
    handleChange: ((key: any, value: any, storage?: boolean) => void) | undefined
    handleDateComplete: ((dateComplete: boolean) => void) | undefined;
    handleEmailVal: ((emailVal: boolean) => void) | undefined;
    handleMessError: ((error: boolean, type: string) => void) | undefined;
}

export class SwitcherRfp extends React.Component<TProps, any>{
    constructor(props: TProps) {
        super(props);
    }
    render(){
        switch (this.props.step) {
            case 1:
                return (
                    <CalendarRfp 
                    onDateComplete={this.props.handleDateComplete}
                    onDateChange={this.props.handleChange} 
                    // onDateStartChange={this.props.handleDateStart} 
                    // onDateEndChange={this.props.handleDateEnd} 
                    dateStart={this.props.dateStart} 
                    dateEnd={this.props.dateEnd} />
                )
            case 2:
                return (
                    <BudgetRfp
                    onBudgetChange={this.props.handleChange}
                    budget={this.props.budget} />
                )
            case 3 :
                return(
                    <RatesRfp
                    updateTitleInfo={this.props.updateTitleInfo} 
                    // onChangeTitles={this.props.handleChangeRates}
                    handleChange={this.props.handleChange}  
                    handleMessError={this.props.handleMessError}
                    // onTitleCommentChange={this.props.handleTitleComment} 
                    titleComment={this.props.titleComment}  
                    titleInfo={this.props.titleInfo}  
                    rates={this.props.rates} 
                    selectedIndex={this.props.selectedIndex}
                    titles={this.props.titles} />
                )
            case 4:
                return (
                    <SummaryRfp 
                    handleChange={this.props.handleChange}
                    onEmailVal={this.props.handleEmailVal} 
                    // onCommentChange={this.props.handleComment} 
                    // onEmailChange={this.props.handleEmail} 
                    // onPhoneChange={this.props.handlePhone} 
                    dateStart={this.props.dateStart} 
                    dateEnd={this.props.dateEnd} 
                    budget={this.props.budget} phone={this.props.phone} email={this.props.email} comment={this.props.comment} titles={this.props.titles} />
                )
            case 5:
                return (
                    <SuccessRfp />
                )
            default:
                return (
                    <div>
                        &nbsp;
                    </div>
                )
        }
    }
}