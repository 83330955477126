import React, { Component} from 'react';
import ErrorRfp from './ErrorRfp';

export default class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props:any) {
      super(props);
      this.state = { 
          hasError: false 
        };
    }
  
    componentDidCatch(error:any, info:any) {
        console.log(info)
      // Affiche une UI de repli
      this.setState({ hasError: true });
      // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    //   logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // Vous pouvez afficher n'importe quelle UI de repli.
        return (
            <ErrorRfp />
        )
      }
      return this.props.children;
    }
  }