import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";

import Rfp from "./components/Rfp"

function App() {
  return (
    <BrowserRouter>
      <Route path="/:id" exact={true} component={Rfp} />
    </BrowserRouter>
  );
}

export default App;
