import React, { Component} from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
// import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import validator from 'validator';

import '../style/rfp.css'

export default class SummaryRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            phone: this.props.phone,
            email: this.props.email,
            comment: this.props.comment,
            collapseRates: {},
            chevron: {}
        }
    }

    componentDidMount(){
        if(this.state.email !== undefined){
            if(validator.isEmail(this.state.email)){
                this.props.onEmailVal(true)
            } else {
                this.props.onEmailVal(false)
            }
        }
    }
    ratesCollapse(title: any){
        this.setState({
            collapseRates: {
                ...this.state.collapseRates,
                [title]: !this.state.collapseRates[title]
            },
            chevron: {
                ...this.state.chevron,
                [title]: !this.state.chevron[title]
            }
        });
    }
    render(){
        const titles = this.props.titles;
        const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]')
        const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]')
        const titleData = {
            media_vehicles : titles.map((title: any)=>{

                var srdsMid = selectedRates.filter((rate: any)=>{
                    return rate.srds_mid === title.id ? true : false
                }).map((rate: any)=>{return rate.srds_mid}).filter((val:any,id:any,array:any)=>{
                    return array.indexOf(val) === id;  
                })
                var vehiName = selectedRates.filter((rate: any)=>{
                    return rate.srds_mid === title.id ? true : false
                }).map((rate: any)=>{return title.name}).filter((val:any,id:any,array:any)=>{
                    return array.indexOf(val) === id;  
                })
                var mediaCharac = selectedRates.filter((rate: any)=>{
                    return rate.srds_mid === title.id ? true : false
                }).map((rate: any)=>{return title.media_characteristic}).filter((val:any,id:any,array:any)=>{
                    return array.indexOf(val) === id;  
                })

                if(srdsMid.length === 0){
                    var srdsMid = titleInfo.filter((rate: any)=>{
                        return rate.srds_mid === title.id ? true : false
                    }).map((rate: any)=>{return rate.srds_mid}).filter((val:any,id:any,array:any)=>{
                        return array.indexOf(val) === id;  
                    })
                }
                if(vehiName.length === 0){
                    var vehiName = titleInfo.filter((rate: any)=>{
                        return rate.srds_mid === title.id ? true : false
                    }).map((rate: any)=>{return title.name}).filter((val:any,id:any,array:any)=>{
                        return array.indexOf(val) === id;  
                    })
                }
                if(mediaCharac.length === 0){
                    var mediaCharac = titleInfo.filter((rate: any)=>{
                        return rate.srds_mid === title.id ? true : false
                    }).map((rate: any)=>{return title.media_characteristic}).filter((val:any,id:any,array:any)=>{
                        return array.indexOf(val) === id;  
                    })
                }

                return {
                    srds_mid : srdsMid, 
                    vehicle_name : vehiName,
                    media_characteristic : mediaCharac,
                    price : selectedRates.filter((rate: any)=>{
                        return rate.srds_mid === title.id ? true : false
                    }).reduce((total:any, currentValue:any) => total = total + currentValue.rate_value,0),
                    rates : selectedRates.filter((rate: any)=>{
                        return rate.srds_mid === title.id ? true : false
                    }).map((rate: any)=>{return rate}),

                    comment: titleInfo.filter((info: any)=>{
                        return info.srds_mid === title.id ? true : false
                    }).map((info: any)=>{ return info.titleComment }),
                    file: titleInfo.filter((info: any)=>{
                        return info.srds_mid === title.id ? true : false
                    }).map((info: any)=>{ return info.titleFile ? info.titleFile.name.substr(info.titleFile.name.lastIndexOf('-') + 1) : '' })
                }
            }).filter((title: any)=>{
                return title.srds_mid.length > 0
            })
        }
        return (
            <div className="divSum">
                <div className="divTitle">
                    <h2 className="styleTitle">Summary</h2>
                </div>
                <div className="divSumma">
                    <div className=" summaryR">
                    { titleData && titleData.media_vehicles.length > 0 ?
                            titleData.media_vehicles.map((title : any, index: any) => (
                                <div className="card pl-3 mb-2" key={title.srds_mid}>
                                    <div className="disFlex selectedTitleCard" key={index}>
                                        <div className="rateCards" onClick={() => this.ratesCollapse(title.srds_mid)}  >
                                            <p><span className="chevron"><FontAwesomeIcon icon={this.state.collapseRates[title.srds_mid]? faAngleUp :faAngleDown} /></span> &nbsp; {title.vehicle_name}</p>
                                        </div>
                                        <div className="mr-2">
                                            <p>
                                                {
                                                    title.price !== 0 ?
                                                        <NumberFormat value={title.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    :
                                                        <FontAwesomeIcon icon={faMinus} />
                                                }
                                            </p>

                                        </div>
                                    </div>
                                    {this.state.collapseRates[title.srds_mid] ? 
                                    <div className={title.comment.length !== 0 ? "selectedRates text-left" : "selectedRates text-left mb-2"}>
                                        <div className="desCard mb-3">
                                            {title.srds_mid[0].replace(/(?=.{3}$)/,'-')}  {title.media_characteristic}
                                        </div>
                                        {
                                            title.rates.map((rate:any, i:any)=>(
                                                <div className="disFlex" key={i}>
                                                    <div className="titleCardsList">
                                                        <p className="m-0"> {rate.rate_name}</p>
                                                    </div>
                                                    <div className="mr-2">
                                                    <p className="priceCardsList m-0">
                                                    <NumberFormat value={rate.rate_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    </p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className="divCardComm">
                                            <hr />
                                            <p  className="titleCardsList">Comments</p>
                                            <span className="textComm">{title.comment}</span>
                                            <hr />
                                            <p  className="titleCardsList">File</p>
                                            <span className="textComm">{title.file}</span>
                                        </div>
                                    </div>
                                    :
                                    ''
                                }
                                </div>
                            ))
                            :
                            ""
                        }
                    </div>

                </div>
                {/* <div className="divTextarea">
                    <textarea value={this.state.comment}
                    onChange={(e: any)=>{
                        this.setState({comment: e.target.value})
                        this.props.handleChange("comment", e.target.value, true)
                    }} 
                    // onChange={this.handleChangeComment} 
                    className="textareaSumm" rows={3}>Do you have a commentary to add ?</textarea>
                </div> */}
                <div className="divTitleCont">
                    Contact
                </div>
                <div className="disFlex paddContact">
                    <div className="inlineB divinputContL">
                        <div className="divInputIcon">
                            <input value={this.state.email !== 'undefined'?this.state.email:''} 
                            onChange={(e: any)=>{
                                this.setState({email: e.target.value})
                                this.props.handleChange("email", e.target.value, true)
                                if(this.state.email !== undefined){
                                    if(validator.isEmail(e.target.value)){
                                        this.props.onEmailVal(true)
                                    } else {
                                        this.props.onEmailVal(false)
                                    }
                                }
                            }} 
                            // onChange={this.handleChangeEmail} 
                            placeholder="Email address" className="inputCont" type="email"/>
                        </div>
                        <div className="divIconMail">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>
                    <div className="inlineB divinputContR">
                        <div className="divInputIcon">
                            <NumberFormat 
                            value={this.state.phone}
                            // format="+1 (###) ###-####"
                            placeholder="Phone number" 
                            allowEmptyFormatting mask="_"
                            className="inputCont"
                            onChange={(e: any)=>{
                                this.setState({phone: e.target.value})
                                this.props.handleChange("phone", e.target.value, true)
                            }}
                            />
                        </div>
                        <div className="divIconTel">
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                    </div>
                </div>
                <p className="contact_info">
                Your RFI request will be sent to your selected publishers. Please provide your preferred contact information for their replies.
                </p>
            </div>
        )
    }
}