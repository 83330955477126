import React, {Component} from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'
class RatesRfp extends Component<any, any> {

    public order: string[] = ["SPREAD B/W","PAGE B/W","2/3 PAGE B/W","1/2 PG ISL B/W","1/2 PAGE B/W","1/3 PAGE B/W","1/4 PAGE B/W","JR PAGE B/W","PAGE 2-C","2/3 PAGE 2-C","1/2 PG ISL 2-C","1/2 PAGE 2-C","1/3 PAGE 2-C","1/4 PAGE 2-C","JR PAGE 2-C","SPREAD 4-C","PAGE 4-C","2/3 PAGE 4-C","1/2 PG ISL 4-C","1/2 PAGE 4-C","1/3 PAGE 4-C","1/4 PAGE 4-C","JR PAGE 4-C","PAGE FRONT COVER","PAGE 2ND COVER","PAGE 3RD COVER","PAGE 4TH COVER","ANY COVER","ANY 1/2 COVER"];

    constructor(props : any){
        super(props);
        this.handleChangeTitleComment = this.handleChangeTitleComment.bind(this)
        this.onClickAddRate = this.onClickAddRate.bind(this);
        this.state = {
            selectedRates:JSON.parse(localStorage.getItem('selectedRates') || '{}'),
            button: localStorage.getItem('selectedRates')  ? JSON.parse(localStorage.getItem('selectedRates') || '{}').map((rate: any,index:any)=> rate.srds_mid+'-'+rate.rate_name) :  [],
            titleComment: this.props.titleComment,
            addButton: true,
            titleFile: '',
        };
    }


    sortRates = (a: any, b: any) => {
        if (this.order.indexOf(a.rate_name) == -1){
            console.log(a.rate_name);
        }
        return this.order.indexOf(a.rate_name) - this.order.indexOf(b.rate_name);
    };
    onClickAddRate(rate: any,index:number){
        if(!localStorage.getItem('selectedRates')){
            this.setState({selectedRates:[rate],button:[...this.state.button, rate.srds_mid+'-'+rate.rate_name]},()=>{
                localStorage.setItem('selectedRates',JSON.stringify(this.state.selectedRates))
            });
        }else{
            const rates = this.state.selectedRates.filter((e:any)=>{
                if(rate.srds_mid+'-'+rate.rate_name=== e.srds_mid+'-'+e.rate_name){
                    return e;
                }else{return ''}
            })
            if(rates.length === 0) {
                this.setState({
                    selectedRates:[...this.state.selectedRates,rate],
                    button: [...this.state.button, rate.srds_mid+'-'+rate.rate_name],
                },()=>{
                    localStorage.setItem('selectedRates',JSON.stringify(this.state.selectedRates))
                })

            }
        }
        
    }
    onClickRemoveRate(rate: any, index:number){
        this.setState({selectedRates: this.state.selectedRates.filter((e:any) => {
            if(rate.srds_mid+'-'+rate.rate_name  !== e.srds_mid+'-'+e.rate_name ){
                return e;
            }else{return ''}
        }),
        button: this.state.button.filter((rateIndex:any) => {
            if(rate.srds_mid+'-'+rate.rate_name!== rateIndex){
                return rateIndex 
            }else{return ''}
        })  
        },()=>{
            localStorage.setItem('selectedRates',JSON.stringify(this.state.selectedRates))
        });

    }
    handleChangeTitleComment(e: any){
        this.setState({titleComment: e.target.value})
        this.props.onTitleCommentChange(e.target.value)
    }
    handleDeleteFile(srds_mid:any){
            this.props.updateTitleInfo({"titleFile":'', "srds_mid":srds_mid})
            this.setState(null);
    }
    handleUploadFile(e:any, srds_mid:any){
        let upload = e.target.files[0];
        // check si upload n'est pas nul (sinon problème dans IE pour le reupload)
        if(upload !== null){
            if(upload.size <= 5000000){
                this.setState({titleFile: upload});
                upload.srds_mid = srds_mid;
                let formData = new FormData();
                formData.append('srds_mid',srds_mid);
                formData.append('file', upload);
                axios.post(`https://service.rfp.adwanted.com/rfp/upload`, formData).then((res:any) => {
                    this.props.updateTitleInfo({"titleFile":res.data, "srds_mid":srds_mid})
                })
            } else {
                this.props.handleMessError(true, 'fichier')
            }
        }
        e.target.value = null;
    }

render(){
        const rates = JSON.parse(localStorage.getItem('rates') || '[]');
        const frequency: number = rates.length > 0 ? Math.min(...rates.map((r:any)=>{return r.frequency})) : 0;
        const filteredRates = rates.filter((rate:any) => {return rate.frequency === frequency});

    const ratesSrdsId = localStorage.getItem('selectedTitle');
        const titles = this.props.titles;
        // const ratesSrdsId = rates[0].srds_mid
        const style = {
            padding: "10px",
            textAlign: 'left' as const
          };
          const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
          const titleFile = titleInfo.length > 0 ? titleInfo.filter((info:any)=>{
                             return info.srds_mid === ratesSrdsId ? true:false
                            }).map((info:any) => {return info.titleFile ? info.titleFile.name.substr(info.titleFile.name.lastIndexOf('-') + 1) : ''} ) : ''
        const titleComment = this.props.titleInfo.length > 0 ? this.props.titleInfo.filter((info:any)=>{
            return info.srds_mid === ratesSrdsId;
            }).map((info:any) => {return info.titleComment} ) : this.state.titleComment;
    return(
            <div className="titleRates">
                <div className="divTitle">
                    <h2 className="styleTitle">Add Units to Campaign </h2>
                    <p className="text-white">The below estimated costs are based on publisher open-rate data. Rates and discounts can be requested in the comments.</p>
                </div>
                <div className="selectedTitle bg-white" style={style}>
                    {
                        titles.map((title : any, idx : any) => (
                            title.id === ratesSrdsId ?
                                <div key={idx} className="divRateTitle">
                                    <h4 className="rateTitle">{title.name} </h4>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td className="tdTitleRates"><p className="desCard">{title.id.replace(/(?=.{3}$)/,'-') + " " + title.media_characteristic}</p></td>
                                                {
                                                 frequency !== 0 ?
                                                 <td className="tdPriceRates"><p>{frequency + 'x'}</p></td>
                                                 :
                                                 ''
                                                }
                                                <td className="tdRates"></td>
                                            </tr>
                                        </thead>  
                                    </table>
                                    
                                </div>
                            :
                            ''
                        ))
                        }
                    <div className="rate">
                        <table className="table table-striped">
                           
                            {/* {
                                rates && rates.length > 0 ?
                                    <tr>
                                    <th scope="col" className="thNameRate">Rate name</th>
                                    <th scope="col" className="thFreqRate">Frequency</th>
                                    <th scope="col" className="thvalRate">value</th>
                                    <th scope="col"></th>
                                    </tr>
                                :
                                    ''
                            } */}
                            
                            <tbody>
                                { filteredRates && filteredRates.length > 0 ?
                                    filteredRates.sort(this.sortRates).map((rate : any, index: any) => (
                                        <tr key={rate.rate_name+index}>
                                            <td className="tdTitleRates">{rate.rate_name}</td>
                                            {/* <td className="tdFreqRates">{rate.frequency} ti</td> */}
                                            <td className="tdPriceRates">
                                                <NumberFormat value={rate.rate_value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </td>
                                            <td className="tdRates">
                                                { !this.state.button.includes(rate.srds_mid+'-'+rate.rate_name)?
                                                    <Button className="btn-success btnPlus" onClick={() => this.onClickAddRate(rate,index)}><FontAwesomeIcon className="fontPlus" icon={faPlus} /></Button>
                                                    :
                                                    <Button className="btn btnCheck" onClick={() => this.onClickRemoveRate(rate,index)}><FontAwesomeIcon className="fontPlus" icon={faCheck} /></Button> 
                                                }
                                            </td>
                                        </tr>
                                    ))
                                :
                                    <div className="styleNoRate">
                                        Rates not currently available in SRDS. Please contact publisher below for rate information.
                                    </div>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className="titleForm mt-3 row text-white">
                        <div className="divTextarea col-6">
                            <p className="pBoldTitle">Comment to Publisher</p>
                            
                            <textarea data-titleid={ratesSrdsId} value={titleComment}
                            onChange={(e: any)=>{
                                    this.setState({titleComment: e.target.value})
                                    this.props.handleChange("titleComment", e.target.value, true)
                                    this.props.updateTitleInfo({"titleComment":e.target.value, "srds_mid":ratesSrdsId})
                                }} 
                            className="textareaSumm" rows={3}></textarea>
                            <p className="info_sup">Provide campaign details, request discounts, ask questions, and more.</p>
                        </div>
                        <div className="fileDif col-6 ">
                            <p className="pBoldTitle">Upload Document</p>
                            
                            <div className="input-group">
                                <div className="custom-file ">
                                    <input  
                                        type="file" 
                                        onChange={(e: any)=>{
                                            this.handleUploadFile(e,ratesSrdsId)
                                        }} 
                                        className="custom-file-input divTextarea" 
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01" 
                                        
                                    />
                                    <label className="custom-file-label"  htmlFor="inputGroupFile01" placeholder="No file selected">{titleFile.toString().substr(0,30)}</label>
                                </div>
                            </div>
                            {
                                titleFile != ''?
                                <p className="clearButton"  onClick={(e: any)=>{
                                        this.handleDeleteFile(ratesSrdsId)
                                    }}>

                                Clear attachment</p>
                                : ''
                            }   
                            <p className="info_sup">Provide RFP or other supporting documents.</p>    
                        </div>
                    </div>
            </div>
        );
    }
}

export default RatesRfp;
