import React, { Component} from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import '../style/rfp.css'
import { Console } from 'console';
// import { title } from 'process';

export default class ButtonRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.handleClickNext = this.handleClickNext.bind(this)
        this.handleClickPrev = this.handleClickPrev.bind(this)
        this.handleClickValidate = this.handleClickValidate.bind(this)
        this.handleClickBackHome = this.handleClickBackHome.bind(this)
        this.state = {
            currentStep: this.props.currentStep,
            titles : this.props.titles,
            selectedIndex : this.props.selectedIndex
        }
    }

    handleClickNext(){
        if(this.props.dateComplete){
            let currentStep = this.props.currentStep
            let selectedIndex = '';
            if(this.props.selectedIndex == undefined){
                selectedIndex = JSON.parse(localStorage.getItem('selectedIndex') || '[]');
            }else{
                selectedIndex = this.props.selectedIndex;
            }

            const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]');
            const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
            var idTitle = this.props.titles[selectedIndex].id
            // if(this.props.titles.length > 0 && this.props.titles[selectedIndex] !== undefined){
            //     idTitle = this.props.titles[selectedIndex].id
            // }
            if(currentStep <= 2){
                selectedIndex = selectedIndex + 1
                if(currentStep !== undefined){
                    currentStep = currentStep >= 3 ? 4: currentStep + 1
                }
                console.log(selectedIndex);
                console.log(currentStep);
                if(currentStep !== 4 || selectedIndex === this.props.titles.length){
                    this.setState({
                        currentStep: currentStep
                    })
                    switch (currentStep) {
                        case 2:
                            this.props.switchStep('date')
                            break;
                        case 3:
                            this.props.switchStep('budget')
                            break;
                        
                        default:
                            break;
                    }
                    this.props.onCurrentStepChange(currentStep)
                }else{
                    if(selectedIndex === this.props.titles.length)
                        return;
                    this.props.handleToUpdate(this.props.titles[selectedIndex].id, selectedIndex)
                }
            } else if (selectedRates.map(function(e:any) { return e.srds_mid; }).indexOf(idTitle) !== -1 || titleInfo.map(function(e:any) { return (e.titleComment !== undefined && e.titleComment.trim() !== "") || e.titleFile ?  e.srds_mid : e.srds_mid = null; }).indexOf(idTitle) !== -1 || this.props.titles.length === 0){
                selectedIndex = selectedIndex + 1
                if(currentStep !== undefined){
                    currentStep = currentStep >= 3 ? 4: currentStep + 1
                }
                if(currentStep !== 4 || selectedIndex === this.props.titles.length || this.props.titles.length === 0){
                    this.setState({
                        currentStep: currentStep
                    })

                    if(currentStep === 4){
                        this.props.switchStep('rate')
                    }
                    // all titles 
                    if(this.props.titles.length == this.getTitleCount()){
                        this.props.onCurrentStepChange(currentStep)
                    }else{
                        const nonSelectedTitles  = this.getNonSelectedTitles(this.props.titles, selectedRates,titleInfo)
                        const newIndex  = this.props.titles.indexOf(nonSelectedTitles[0]);
                        localStorage.setItem('selectedIndex', JSON.stringify(newIndex));
                        localStorage.setItem('selectedTitle',nonSelectedTitles[0].id );  
                        this.props.handleToUpdate(nonSelectedTitles[0].id, newIndex)
                    }
                }else{
                    if(selectedIndex === this.props.titles.length)
                        return;
                    if(this.props.titles.length > 0){
                        this.props.handleToUpdate(this.props.titles[selectedIndex].id, selectedIndex)
                    }
                }
            } else {
                this.props.handleMessError(true, 'rate')
            }
        } else {
            this.props.handleMessError(true, 'date')
        }
    }
    getTitleCount(){
        const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]');
        const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
    
        const titleCount:any = [] 
        selectedRates.map((e:any)=>{
          if (titleCount.indexOf(e.srds_mid) === -1) {
              titleCount.push(e.srds_mid)
          }
          })
          titleInfo.map((e:any)=>{
              if (titleCount.indexOf(e.srds_mid) === -1) {
                  titleCount.push(e.srds_mid)
              }
              })
          return titleCount.length;
    }
    getNonSelectedTitles = (titles:any, selectedRates:any, titleInfos:any)=>{
       const nonSelected = titles.filter((title:any)=>{
            return !selectedRates.some((rate:any)=>{
                return rate.srds_mid === title.id
            });
        })
        return  nonSelected.filter((title:any)=>{
            return !titleInfos.some((info:any)=>{
                return info.srds_mid === title.id
            })
        })
    }
    handleClickPrev(){
        let currentStep = this.props.currentStep
        let selectedIndex = this.props.selectedIndex 
        if(currentStep !== undefined){
            currentStep = currentStep <= 1 ? 1: currentStep - 1
        }
        if(currentStep !== 2 || selectedIndex === 0 || this.props.titles.length === 0){
            this.setState({
                currentStep: currentStep
            })
            this.props.onCurrentStepChange(currentStep)
        }else{
            selectedIndex = selectedIndex - 1
            if(this.props.titles.length > 0){
                this.props.handleToUpdate(this.props.titles[selectedIndex].id, selectedIndex)
            }
        }
    }

    handleClickValidate =  ()=>{
        if(!this.props.emailVal){
            this.props.handleMessError(true, 'mail')
        } else {
            const titles = JSON.parse(localStorage.getItem('title') || '{}');
            const selectedRates = JSON.parse(localStorage.getItem('selectedRates') || '[]');
            const titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
            const initId = localStorage.getItem('initId');
            const data = {
                general: {
                    rfp_model: 1,
                    creation_date : Date.now(),
                    email: localStorage.email,
                    phone: localStorage.phone,
                    company:localStorage.company,
                    initId: initId,
                },
                rfp:{
                    period: {
                        campaign_start: localStorage.dateStart,
                        campaign_end: localStorage.dateEnd 
                    },
                    media_vehicles: titles.map((title: any)=>{
                        return {
                            id_vehicle: title.id,
                            name_vehicle:title.name,
                            media_characteristic:title.media_characteristic,
                            contacts : title.contact,
                            rates: selectedRates.filter((rate: any)=>{
                                return rate.srds_mid === title.id ? true : false
                            }).map((rate: any)=>{return rate}),
                            comment: titleInfo.filter((info:any)=>{
                                return info.srds_mid === title.id ? true:false
                            }).map((info:any) => {return info.titleComment} ),
                            filename: titleInfo.filter((info:any)=>{
                                return info.srds_mid === title.id ? true:false
                            }).map((info:any) => {return info.titleFile ? info.titleFile.name.substr(info.titleFile.name.lastIndexOf('-') + 1) :  ''} ),
                            filepath: titleInfo.filter((info:any)=>{
                                return info.srds_mid === title.id ? true:false
                            }).map((info:any) => {return info.titleFile ? info.titleFile.path :''} )
                        }
                    }),
                    constraint: [{
                        typeConstraint:'Number',
                        name:'budget',
                        properties:[{
                            currency: "euro",
                            value: localStorage.budget ? localStorage.budget.replace(/\D/g,'') : 0,
                        }]

                    }],                    
                }
            }
            axios.post(`https://service.rfp.adwanted.com/rfp`, data).then((res:any) => {                
                let currentStep = this.props.currentStep
                if(currentStep !== undefined){
                    currentStep = currentStep >= 4 ? 5: currentStep + 1
                }
                this.setState({
                    currentStep: currentStep
                })
                this.props.onCurrentStepChange(currentStep)
                localStorage.clear()
            })
        }
    }

    handleClickBackHome(){
        let currentStep = 1
        this.setState({
            currentStep: currentStep
        })
        localStorage.clear()
        this.props.onInitState()
    }

    render(){
        return (
            <div>
                <div className="disFlex disFlex_button">
                    <div className="btnPrev inlineB mb-3">
                        {
                            this.props.currentStep < 5 ?
                                this.props.currentStep > 1 ?
                                    <Button className="buttonRfp" onClick={this.handleClickPrev}><FontAwesomeIcon icon={faArrowLeft} /> PREVIOUS</Button>
                                :
                                    ''
                            :
                                ''
                                // <Button className="buttonBackHome" onClick={this.handleClickBackHome}><FontAwesomeIcon icon={faArrowLeft} /> BACK TO THE HOMEPAGE</Button>
                        }
                    </div>
                    <div className="btnCont inlineB">

                        {
                            this.props.currentStep < 5 && this.props. titles.length > 0 ?
                                this.props.currentStep > 3 ?
                                    // this.props.emailVal ? 
                                        <Button className="buttonRfpGreen" onClick={this.handleClickValidate}>SEND <FontAwesomeIcon icon={faCheck} /></Button>
                                    // :
                                        // <Button disabled className="buttonRfpGreen">SEND <FontAwesomeIcon icon={faCheck} /></Button>
                                :
                                    <Button className={this.props.dateEnd !== '' || this.props.currentStep > 1 ? "buttonRfpGreen" :  "buttonRfp"} onClick={this.handleClickNext}>CONTINUE <FontAwesomeIcon icon={faArrowRight} /></Button>
                            :
                                ""
                        }
                    </div>
                </div>
            </div>
        )
    }
}
