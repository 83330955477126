import React, { Component} from 'react';
// import Input from 'react-input-range';
import NumberFormat from 'react-number-format';

import "react-input-range/lib/css/index.css";
import '../style/rfp.css'

export default class BudgetRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.handleChangeBudget = this.handleChangeBudget.bind(this)
        this.state = {
            budget: this.props.budget ? this.props.budget : '',
        }
    }

    handleChangeBudget(val: any){
        this.setState({ 
            budget: val,
        }, ()=>{this.props.onBudgetChange("budget", val, true)})
    }

    render(){
        return (
            <div className="divBud">
                <div className="divTitle">
                    <h2 className="styleTitle">Enter Campaign Budget</h2>
                    <p>
                        Enter a budget to track your campaign costs. This is optional and will not be shared with any publishers.
                    </p>
                </div>
                <div className="divRange">
                    <p className="text-light text-left font-weight-bold">Enter estimated gross budget of campaign</p>
                        <NumberFormat
                            className="inputCurrency" 
                            value={this.state.budget}
                            onChange={(e: any)=>{
                                this.handleChangeBudget(e.target.value)
                            }}
                            thousandSeparator={true} 
                            prefix={'$'} 
                        />
                </div>
            </div>
        )
    }
}