import React, { Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes} from '@fortawesome/free-solid-svg-icons'
import logo from '../images/srdsLogo.png';

import '../style/rfp.css'

export default class ErrorRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    render(){
        return (
            <div className="disFlex height contenant">
                <div className="container pt-3 inlineB height">
                    <div className="divL">
                        <div className="divTitleSom disFlex">
                            <div className="width50">
                                <h3 className="titleColor">
                                    <img src={logo} alt="" style={{maxWidth:"170px"}}/>
                                </h3>
                            </div>
                            <div className="width50 colorGrey">
                                Streamline the ad buying process by negotiating rates and sending RFIs via SRDS.&nbsp; 
                                <a href="https://next.srds.com/resources/programmatic-resources/buying" target="_blank">  Learn more</a>
                            </div>
                        </div>
                        <div className="divSucc">
                            <div className="titleSuccW">
                            </div>
                            <div className="titleSuccB">
                                <h2 className="text-dark text-bold">OOPS !</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-3 inlineB backgroundBleu height paddingR">
                    <div className="divSucc">
                        <div className="checkError">
                            <FontAwesomeIcon className="iconTimes" icon={faTimes} />
                        </div>
                        <div className="titleSuccW">
                            <h2>An error has occured</h2>
                        </div>
                        <div className="titleSuccB">
                            <p>The page you are looking for is not available or an error has been make.Please try again later.</p>
                        </div>
                        <div className="titleContact">
                            <p>If you have questions or need assistance, contact SRDS client service at 800.851.SRDS or campaigns@srds.com</p>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            

        )
    }
}