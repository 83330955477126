import React, { Component} from 'react';
import StepperRfp from './StepperRfp'
import ButtonRfp from './ButtonRfp'
import moment from 'moment'
// import NotifRfp from './NotifRfp'
// import ChatRfp from './ChatRfp'
import CartRfp from './CartRfp'
import axios from 'axios';
import {RfpObject} from './RfpObject.bin'
import { SwitcherRfp } from './SwitcherRfp';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../style/rfp.css';
import ErrorBoundary from './ErrorBoundary';
// import { Console } from 'console';

const init = new RfpObject()
export default class Rfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.handleCurrentStep = this.handleCurrentStep.bind(this)
        this.handleDateComplete = this.handleDateComplete.bind(this)
        this.handleEmailVal = this.handleEmailVal.bind(this)
        this.handleInitState = this.handleInitState.bind(this)
        this.updateTitleInfo = this.updateTitleInfo.bind(this)
        this.handleMessError = this.handleMessError.bind(this)
        this.onClickOk = this.onClickOk.bind(this)
        this.switchStep = this.switchStep.bind(this)
        if(localStorage.getItem('title') || localStorage.getItem('email')){
            let titleStorage : any  = localStorage.getItem('title');
            let emailLoc : any = localStorage.getItem('email')
            const titlesLoc = JSON.parse(titleStorage)
            emailLoc = emailLoc.replace(/"/gi, "")
            var dateStartLoc : any = ''
            var dateEndLoc : any = ''
            var phoneLoc : any = ''
            var commentLoc : any = ''
            var titleCommentLoc : any = ''
            var titleFileLoc : any = ''
            var budgetLoc : any = ''
            var titleInfo : any = []
            var dateCompl : any = false
            var dateBool : any = false
            var budgetBool : any = false
            var rateBool : any = false
            var currentStep: any = 1
            var selectedIndex: any = 0

            if(localStorage.getItem('dateStart')){
                dateStartLoc = localStorage.getItem('dateStart')
                dateStartLoc = dateStartLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('dateEnd')){
                dateEndLoc = localStorage.getItem('dateEnd')
                dateEndLoc = dateEndLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('dateStart') && localStorage.getItem('dateEnd') ){
                dateCompl = true
                dateBool = true
            }
            if(localStorage.getItem('phone')){
                phoneLoc = localStorage.getItem('phone')
                phoneLoc = phoneLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('comment')){
                commentLoc = localStorage.getItem('comment')
                commentLoc = commentLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('titleComment')){
                titleCommentLoc = localStorage.getItem('titleComment')
                titleCommentLoc = titleCommentLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('titleFile')){
                titleFileLoc = localStorage.getItem('titleFile')
                titleFileLoc = titleFileLoc.replace(/"/gi, "")
            }
            if(localStorage.getItem('budget')){
                budgetLoc = localStorage.getItem('budget')
                budgetLoc = budgetLoc.replace(/"/gi, "")
                budgetBool = true
            }
            if(localStorage.getItem('titleInfo')){
                titleInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
            }
            if(localStorage.getItem('currentStep')){
                currentStep = JSON.parse(localStorage.getItem('currentStep') || '1');
            }
            if(localStorage.getItem('selectedIndex')){
                selectedIndex = JSON.parse(localStorage.getItem('selectedIndex') || '1');
            }
            if(currentStep >= 2){
                dateBool = true
            }
            if(currentStep >= 3){
                budgetBool = true
            }
            if(currentStep >= 4){
                rateBool = true
            }
            
            const local = {
                dateStart: dateStartLoc,
                dateEnd: dateEndLoc,
                dateComplete: dateCompl,
                error: false,
                messError: '',
                titleError : '',
                currentStep: currentStep,
                budget: budgetLoc,
                phone: phoneLoc,
                email: emailLoc,
                titles: titlesLoc,
                comment: commentLoc,
                titleComment: titleCommentLoc,
                titleInfo : titleInfo,
                titleFile: titleFileLoc,
                emailVal: false,
                dateBool: dateBool,
                budgetBool: budgetBool,
                rateBool: rateBool,
                rates:'',
                selectedIndex:selectedIndex
            }
            this.state = local
        } else {
            this.state = init
        }
    }
    handleToUpdate(title:any, index:number){
        if(index === -1){
            index = this.state.titles.map(function(e:any) { return e.id; }).indexOf(title)
            this.setState({
                selectedIndex:index,
                currentStep: 3
            })
        }else{
            this.setState({
                selectedIndex:index,
            })
        }

        localStorage.setItem('selectedIndex', JSON.stringify(index))
        this.updateRates(title);
    }
    handleDateComplete(dateComplete: boolean){
        this.setState({dateComplete: dateComplete})
    }

    handleCurrentStep(currentStep: number){
        localStorage.setItem('currentStep', JSON.stringify(currentStep))
        this.setState({currentStep: currentStep})
    }

    handleEmailVal(emailVal: boolean){
        this.setState({emailVal: emailVal})
    }
    handleInitState(){
        this.setState(init)
        this.componentDidMount()
    }
    handleMessError(error: boolean, type: string){
        this.setState({error: error});
        switch (type) {
            case 'date':
                this.setState({titleError : 'Missing information', messError: 'Please select campaign dates'});
                break;
            case 'mail':
                this.setState({titleError : 'Email error', messError: 'Enter Email please'});
                break;
            case 'rate':
                this.setState({titleError : 'Missing Information', messError: 'Please provide an ad size, comment and/or document for upload.'});
                break;
            case 'fichier':
                this.setState({titleError : 'Missing Information', messError: 'The attached file exceeds the 5MB size limit'});
                break;
            default:
                break;
        }
    }
    switchStep(step: string){
        switch (step) {
            case 'date':
                this.setState({dateBool: true})
                break;
            case 'budget':
                this.setState({budgetBool: true})
                break;
            case 'rate':
                this.setState({rateBool: true})
                break;
            default:
                break;
        }
    }
    onClickOk(){
        this.setState({error: false})
    }
    handleChange = (key :string, value: any, storage?:boolean) => {
        if(storage){
            this.setState({[key]: value}, ()=>{localStorage.setItem((key as string), JSON.stringify(value))})
        }
        else
        {
            this.setState({[key]: value})
        }
    }
    componentDidMount(){
        const id = this.props.location.pathname.substr(1,);
        if(!localStorage.getItem('initId')){
            localStorage.setItem('initId', id)
        }
        if(id !== localStorage.getItem('initId')){
            this.setState(init)
            localStorage.clear();
            localStorage.setItem('initId', id)
        }

        if(localStorage.getItem('title') || localStorage.getItem('email')){
            this.setState({rates:localStorage.getItem('rates')})
        } else {
            axios.get(`https://service.rfp.adwanted.com/srds/init/`+id).then((res) => {            
                res.data.media_vehicles.map((media_vehicle:any)=>{
                if(media_vehicle.media_id){media_vehicle.id = media_vehicle.media_id}
            })
                localStorage.setItem('title', JSON.stringify(res.data.media_vehicles));
                this.setState({titles: res.data.media_vehicles})
                localStorage.setItem('email', res.data.user.email)
                localStorage.setItem('company', res.data.user.company)
                if(res.data.user.email !== undefined){
                    this.setState({email: res.data.user.email})
                }
                this.updateRates(this.state.titles[0].id);
            })
        }
    }
    updateTitleInfo(info:any){
        let storageInfo = JSON.parse(localStorage.getItem('titleInfo') || '[]');
        if(info.titleFile ){
            info.titleFile = {
                name:info.titleFile.filename,
                size:info.titleFile.size,
                type:info.titleFile.mimetype,
                path:info.titleFile.path
            }
        }
        if(storageInfo.length > 0){
            if(storageInfo.some((item :any) => info.srds_mid === item.srds_mid)){
                storageInfo.map((store:any)=>{
                    if(store.srds_mid === info.srds_mid){
                        if('titleComment' in info ){
                            store.titleComment = info.titleComment;
                        }else{
                            if(info.titleFile === ""){
                                store.titleFile = ""
                            }else{
                                store.titleFile = {
                                    name:info.titleFile.name,
                                    size : info.titleFile.size,
                                    type : info.titleFile.type,
                                    path:info.titleFile.path
                                } 
                            }
                        }
                    }
                })
            }else{
                storageInfo.push(info)
            }
            localStorage.setItem('titleInfo', JSON.stringify(storageInfo))
            this.setState({titleInfo:storageInfo})
        }else{
            localStorage.setItem('titleInfo', JSON.stringify([info]))
            this.setState({titleInfo:info})
        }
    }
    updateRates =  async (title: any) => {
        const id = this.props.location.pathname.substr(1,);
        await axios.get(`https://service.rfp.adwanted.com/srds/`+ title + '/' + id).then((res) => {
            localStorage.setItem('rates', JSON.stringify(res.data.rates))
            localStorage.setItem('selectedTitle', title)
            this.setState({rates:localStorage.getItem('rates')})
            this.setTitleContact(res.data.contacts)
        })
    }
    setTitleContact = (contacts:any)=>{
        const titles = JSON.parse(localStorage.getItem('title') || '[]');
        titles.map((title:any)=>{
            if(title.id == contacts[0].srds_mid){
                title.contact = [contacts[0].contact_info]
            }
        })
        localStorage.setItem('title', JSON.stringify(titles))
        this.setState({titles: titles})
    }
    render(){
        var handleToUpdate  =   this.handleToUpdate;
        return (
            <ErrorBoundary>
            <div className="disFlex height contenant">
                <div className="container pt-3 inlineB height">
                {/* <ErrorBoundary> */}
                    <StepperRfp
                    handleChange={this.handleChange} 
                    handleToUpdate={handleToUpdate.bind(this)} 
                    selectedIndex={this.state.selectedIndex}
                    currentStep={this.state.currentStep}
                    onCurrentStepChange={this.handleCurrentStep}
                    rates={this.state.rates} titles={this.state.titles} 
                    budget={this.state.budget}
                    dateStart={this.state.dateStart}
                    dateEnd={this.state.dateEnd}
                    dateBool={this.state.dateBool}
                    budgetBool={this.state.budgetBool}
                    rateBool={this.state.rateBool}
                    />
                {/* </ErrorBoundary>         */}
                </div>
                {
                    this.state.error ?

                    confirmAlert({
                        customUI: ({ onClose }) => {
                            return (

                                <div className='divAlert_react divAlert_react'>
                                <div className="divAlert_fond p-4">
                                    <div>
                                        <span className="divIconAlert">
                                            <FontAwesomeIcon icon={faExclamationTriangle} />
                                        </span>
                                        <h5>{this.state.titleError}</h5> 
                                    </div>
                                    <div className="divAlert_texte">                        
                                    <p> {this.state.messError}</p>    
                                    </div>
                                    <div className="divBtnOk">
                                        <button className="btn btnOk btnClose" onClick={()=>{
                                            onClose();
                                            this.onClickOk();
                                        }}>Close</button>
                                    </div>
                                </div>
                            </div>
                            )}})

                    :
                        ""
                }
                <div className="container pt-3 inlineB backgroundBleu height paddingR">
                    {/* <div className="divNotif">
                        {
                            this.state.currentStep < 5 ?
                                <CartRfp
                                handleChange={this.handleChange} 
                                titles={this.state.titles} />
                            :
                                ''
                        }
                    </div> */}
                    <div className="divR">
                    {/* <ErrorBoundary>         */}
                        <SwitcherRfp 
                        step={this.state.currentStep}
                        handleChange={this.handleChange}
                        handleDateComplete={this.handleDateComplete}
                        handleEmailVal={this.handleEmailVal}
                        handleMessError={this.handleMessError}
                        dateStart={this.state.dateStart}
                        dateEnd={this.state.dateEnd}
                        comment={this.state.comment}
                        titleComment={this.state.titleComment}
                        titleInfo={this.state.titleInfo}
                        updateTitleInfo={this.updateTitleInfo}
                        rates={this.state.rates}
                        titles={this.state.titles}
                        budget={this.state.budget}
                        phone={this.state.phone}
                        selectedIndex={this.state.selectedIndex}
                        email={this.state.email}/>
                    {/* </ErrorBoundary>         */}
                    </div>
                    {/* <ErrorBoundary>         */}
                        <ButtonRfp 
                        onInitState={this.handleInitState} 
                        onCurrentStepChange={this.handleCurrentStep} 
                        switchStep = {this.switchStep}
                        currentStep={this.state.currentStep} 
                        handleMessError={this.handleMessError} 
                        dateComplete={this.state.dateComplete} 
                        dateEnd={this.state.dateEnd}
                        emailVal={this.state.emailVal} 
                        titleInfo={this.state.titleInfo}
                        titles={this.state.titles}
                        selectedIndex={this.state.selectedIndex}
                        handleToUpdate={handleToUpdate.bind(this)} 
                        rates={this.state.rates}
                        />
                    {/* </ErrorBoundary>         */}
                </div>
            </div>
            </ErrorBoundary>
        )
    }
}
