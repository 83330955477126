export class RfpObject {
    dateStart: string = '';
    dateEnd: string = '';
    error: boolean = false;
    messError: string = '';
    dateComplete: boolean = false;
    currentStep: number =  1;
    budget: number = 0;
    phone: string =  '';
    email: string =  '';
    titles: string =  '';
    comment: string =  '';
    titleComment: string =  '';
    emailVal: boolean = false;
    dateBool: boolean = false;
    budgetBool: boolean = false;
    rateBool: boolean = false;
    rates: string =  '';
    titleInfo = [];
    selectedIndex: number = 0;
}