import React, { Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/free-solid-svg-icons'

import '../style/rfp.css'

export default class SuccessRfp extends Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    render(){
        return (
            <div className="divSucc">
                <div className="checkSucc">
                    <FontAwesomeIcon className="iconCheck" icon={faCheck} />
                </div>
                <div className="titleSuccW">
                    <h2>Your Request Has Been Sent !</h2>
                </div>
                <div className="titleSuccB">
                    <p>A summary of your request has been emailed to you.</p>
                </div>
                <div className="titleContact">
                    <p>If you have questions or need assistance, contact SRDS client service at 800.851.SRDS or campaigns@srds.com</p>
                </div>
            </div>
        )
    }
}